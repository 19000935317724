import React from 'react';
import {Typography} from "@mui/material";
/**
 * Interface Provider {
    * id: string
    * name: string
    * imageUrl: string
    * description: string
    * specialization: string
    * specialtiesList: string[]
    * educationList: string[]
 *  }
 */
const newClients = "Now accepting new clients!"

const heather_rose_carlson = {
    id: 'heather-rose-carlson',
    name: 'Dr. Heather Rose-Carlson, PsyD, LP',
    clients: '',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\FinalHeadshots\\Heather.png',
    description: 'As a native Duluthian, I grew up learning to embrace all that the Northland has to offer. With my husband and two fantastic children, we enjoy kayaking, gardening, fishing, berry picking, and a warm campfire with friends and family. Outside of the office, it has been an honor to work with our veterans and our community. Through Project New Hope, I am part of a team that provides weekend retreats to veterans and their families at no cost including a female veterans retreat to the BWCA every summer. I also provide Critical Incident Response when traumatic events occur in our community and volunteer for my local Fire Department. My dog, Kenai (5), often joins me in sessions.',
    specialization: 'I provide integrative therapy in a coaching manner. Most clients can identify what is going wrong or what is missing in their lives but need guidance on how to get back on track. People tend to be "stuck" trying what may have worked in the past and are frustrated that these same behaviors, expectations, and coping skills are not working in their current life circumstances.',
    specialtiesList: [
        'First Responders',
        'Veterans',
        'Couples (Gottman trained couples therapist)',
        'Issues: PTSD (PTSI), OCD, Panic, Depression, Anxiety',
        'Trauma-Focused Therapeutic Skills:',
        'Eye Movement Desensitization and Reprocessing (EMDR)',
        'Exposure Response Prevention (ERP)',
        'Imagery Rescripting and Reprocessing Therapy (IRRT)'
    ],
    educationList: [
        'Doctorate of Clinical Psychology (Psy.D.); Minnesota School of Professional Psychology at Argosy University (American Psychological Association accredited program)',
        'Bachelor of Arts, Psychology College of St. Scholastica, Summa Cum Laude'
    ]
}

const alex_ross = {
    id: 'alex-ross',
    name: 'Alex Ross MA, LPCC',
    clients: '',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\FinalHeadshots\\Alex.png',
    description: 'My approach to therapy is to use my knowledge to determine with clients and families what will be most helpful for their specific life circumstances and to meet them where they are in life. I hope to support resilience and provide the ability for individuals of all ages to address stressful life experiences and challenges. I am interested in creating a therapeutic experience that is a good fit for all, across life experiences and circumstances. When I\'m not working, I volunteer with the Arrowhead Critical Incident Stress Management Response team for First Responders, spend time with friends, family, and my dog, and am hoping to make it to every National Park.',
    specialization: 'I currently enjoy and have experience with working with individuals of varying ages in providing services to address traumatic experiences with children, adolescents, and adults as well as other life stressors that may result in depression, anxiety, grief, or other concerns. I am trained and nationally certified in evidence-based practices such as EMDR, CPT, and TF-CBT to work with individuals to determine what is the best fit.',
    specialtiesList: [],
    educationList: [
        'Masters in Counseling Psychology (Bethel University, St. Paul, Minnesota)',
        'Bachelor\'s in Forensic Science, Minor in Psychology (University of North Dakota)',
        'Licensed Professional Clinical Counselor (LPCC)'
    ]
}

const peggy_romero = {
    id: 'peggy-romero',
    name: 'Peggy Romero, MSW, LICSW',
    clients: 'Accepting new clients!',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\FinalHeadshots\\Peggy.png',
    description:  'I come to Northland Psychological Services with 32-plus years of professional experience in the behavioral and physical health fields. Practicing mindfulness therapy, inner-child work, chakra balancing, guided movement meditation, and Reiki (energy work). I believe people are the experts of their own lives and it is not what is wrong with them, it is what they have experienced that can get stuck in their system. I utilize a variety of person-centered approaches to help people address their challenges and what they do with those experiences so they can feel they are no longer “stuck”  and reconnect to self to hold space so they can move forward and live their lives to be their best selves. I have a quirky sense of humor with the belief that laughter is a universal language and a huge component of healing. When I am not practicing as a psychotherapist and energy worker, I enjoy planting flowers, communing with nature and trees, and sitting in Lake Superior. Above all else, I value spending time with my partner of 40 years and reflecting on the love of my mother and beloved Yorkshire companions who are no longer by my side but always in my heart.',
    specialization: 'I work with adults who live with chronic pain and illnesses, terminal illnesses, developmental trauma, PTSD, anxiety, depression, grief/ death, and loss. \n I see adults ages from 25 years old through end stage development. I am trained in Eye Movement and Desensitization and Reprocessing Therapy (EMDR), as well as Induced After Death Communication (IADC), to help heal grief associated with bereavement. Grief movement Guide, Nature-based Experiential Learning (Ecotherapy), and Usui Holy Fire III Reiki Master, as well as Certified in Pet loss therapy.',
    specialtiesList: [],
    educationList: [
        'Masters in Clinical Social Work (MSW) (College of Saint Scholastica)',
        'Bachelor of Psychology (College of Saint Scholastica)',
        'Licensed and Independent Clinical Social Worker (LICSW)'
    ]
}

const rebecca_oachs = {
    id: 'rebecca-oachs',
    name: 'Rebecca Oachs, MSW, LICSW',
    clients: 'Accepting new clients!',
    imageUrl: '\\assets\\images\\northlandOfficePhotos\\FinalHeadshots\\Rebecca.png',
    description: 'I have 10+ years of experience as a therapist. I enjoy working with individuals ages 18+ who are experiencing various types of anxiety, Obsessive Compulsive Disorder, and generational/relational trauma. While in the field, I have received specific training in the impact of complicated family dynamics on individuals and how these symptoms may present in adults. I work to be an excellent critical listener, holding hope for growth and change while providing non-judgmental acceptance in our interactions. My work is strengths-based, that is, focusing on positives while balancing/considering current difficulties and desired changes. I am trained in Accelerated Resolution Therapy, which facilitates processing emotions and sensations tied to trauma, anxiety, and other related difficulties. Outside of the office, I enjoy spending time with my family, traveling, reading, and enjoying the sunshine.',
    specialization: '',
    specialtiesList: [
    'Ages 18+',
    'Anxiety',
    'Depression',
    'OCD',
    'PTSD/Trauma',
    'ART',
    'LGBTQIA',
    'Childhood Abuse/Neglect',
    'I also share my expertise on parent-child relationships through my blog!',
    <Typography component="span" key="blog-link">
        If you are interested,{' '}
        <a 
            href="http://buildingblocksforparenting.wordpress.com" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: '#1976d2', textDecoration: 'underline' }}
        >
            click here!
        </a>
    </Typography>
],
    educationList: [
        'Master\'s in Clinical Social Work (St. Catherine University/University of St. Thomas School of Social Work, St. Paul, MN) (MSW)',
        'Bachelor\'s in Social Work (Concordia College, Moorhead, MN)',
        'Licensed and Independent Clinical Social Worker (LICSW)',
        'Infant Parent Mental Health Post-Graduate Certificate Program (University of Massachusetts, Boston)'
    ]
}


const adam_huot = {
    id: 'adam-huot',
    name: 'Adam Huot, MSW, LGSW ',
    clients: 'Accepting new clients!',
    imageUrl: '\\assets\\images\\updatedpics\\adamHeadshot.jpg',
    description: 'I have over 15 years of Law Enforcement experience as a police officer. I have worked as a patrol officer, investigator, field training officer, and as a member of the crisis negotiation team and peer support team. I obtained a Master\'s of Social Work from the College of St. Scholastica in 2020 because I wanted to serve the first responder community by providing counseling and trauma therapy. I have received training in the areas of trauma treatment, suicidality, critical incident response, and personal wellness. I have been teaching police officers and crisis response workers about post traumatic stress, resilience, and wellness for the last four years. I have also worked as a program staff member and as a therapist at a residential treatment center. My therapeutic focus is mainly on exposure to traumatic incidents and stress injury with a holistic wellness approach, focusing on sleep, exercise and movement, and creating positive connections. I attend CrossFit classes and yoga classes every week as part of my wellness routine, and obtained my CrossFit level 1 in July of 2022. I am a fantasy/sci-fi/action nerd, so anything Lord of the Rings, Game of Thrones, Harry Potter, or Marvel I could spend hours talking about. I have become interested in the brain science behind mental health, stress, trauma, and human connection over the last 5 years, and am always finding new ways to connect that into my practice as a therapist.',
    specialization: '',
    specialtiesList: [
        'First Responders',
        'Treatment of Trauma',
        'Stress Education and Resilience',
        'Critical Incident Stress',
        'Crisis Counseling',
        'Stress Injury',
        'Repeated exposure to trauma',
        'Anxiety',
        'Depression'
    ],
    educationList: [
        'B.A. in Criminology from UMD',
        'Master\'s of Social Work from College of Saint Scholastica',
        'LGSW (License in Graduate Social Work)'
    ]
}

const lidnzi_campbell = {
    id: 'lidnzi-campbell Accepting new clients!',
    name: 'Lindzi Campbell, Master\'s Student Under Supervision',
    clients: 'Accepting new clients!',
    imageUrl: '\\assets\\images\\updatedpics\\lindziHeadshot.jpg',
    description: 'I was born and raised in Duluth Minnesota and had the privilege of growing up on Park Point. This is where I built lifelong friendships and important connections that inspired me to dedicate my life\'s work to the helping professions. My family history is comprised of police officers and firefighters. I am a retired career firefighter and avid Jack of all trades, master of some. I am currently an intern here at Northland Psychological Services pursuing my master\'s degree. My hobbies and interests include home improvement, custom project fabrication, all things outdoors, socializing with friends, cooking, gardening, and basically anything I find my curious mind fixated with on any given day. My greatest passion lies in lending my skills and abilities to improving the safety and the wellbeing of my community.I received an Associate\'s Degree in Fire Technology and Administration from Lake Superior College and soon after became the first woman firefighter to be hired by Superior Fire Department in 1997. I returned to college in 2015 as an alternative(old, but still kickin\') student with the intention to pursue clinical counseling, specializing in trauma informed care with first responders. After six years of perseverance, I received my Bachelors of Applied Science in Psychology, graduating cum laude from the University of Wisconsin Superior. In 2023, I retired after 25 years of firefighting and went on to pursue a Master\'s Degree at the University of Minnesota- Duluth working towards becoming a Licensed Professional Clinical Counselor.',
    specialization: 'Medical and safety professionals consistently show up for people experiencing their very worst moments, putting them at a significantly higher risk for developing stress injuries and mental health disorders that arise from repeat exposure to stress and trauma. I believe that it is important to recognize that first responders face unique stressors that others do not. Having firsthand knowledge and experience of the cumulative effects of these exposures and the cultures in which they exist, I am uniquely suited to serving this population. I look forward to focusing my clinical practice on individuals who have worked on the front lines of emergency response.',
    specialtiesList: [
        'Active and Retired First Responders',
        'Individuals with: Anxiety, PTSD (PTSI), Depression, etc',
        'Adolescents',
        'LGBTQIA+',
    ],
    educationList: [
        'Associates Degree in Fire Technology and Administration from Lake Superior College',
        'B.A.S. in Psychology from University of Wisconsin Superior',
        'Currently pursuing: M.A. of LPCC from University of Minnesota-Duluth'
    ]
}
const christina_finlo = {
    id: 'christina-finlo',
    name: 'Christina Finlo, MA, LFMT, SEP ',
    clients: 'Accepting new clients!',
    imageUrl: '\\assets\\images\\updatedpics\\ChristinaHeadshot.jpg',
    description: 'One side of therapy may involve pursuing wisdom, guidance and support.  The other, involves pursuing healing into our deepest self.  Some concepts to explore are...  How do you make sense of and think about your world and self? How do you experience and manage your emotional world, what are your survival styles and patterns in relationships, distress and behavior? In what ways do you carry emotional pain and trauma on a body level, impacting you in known and unknown ways? I am trained as a Somatic Experiencing Practitioner to work with the release and resolution of trauma that can be trapped in ones physiology and nervous system. I have been in practice for over 12 years, including private practice, community health, and residential treatment. I have worked with all sorts of teens, couples, families and adult individuals. I have experience working with numerous issues involving mood, behavior, trauma, relationships, gender/sexuality, and self-concept.​',
    specialization: '',
    specialtiesList: ['Trauma', 'Shame', 'Toxic loved ones', 'Recovery from religion' ],
    educationList: [
        'BS Alternative Education and Art',
        'MA Adlerian Counseling and Psychotherapy; Adler Graduate School',
        
    ]
}
const claire_dzierzak = {
    id: 'claire-dzierzak',
    name: 'Claire Dzierzak, M.Ed, LPCC',
    clients: '',
    imageUrl: '\\assets\\images\\headshots\\Claire.jpg',
    description: 'I enjoy working with both children and adults as well as families and couples. I approach therapeutic work through a developmental lens that helps to normalize our experiences, minimize comparison, and create space for exploration of self and community. I believe that mental and emotional wellness is innate in each of us and we can work together in therapy and in community to develop healthy habits, relationships, and supports for ourselves and one another. I enjoy working with clients who are navigating life transitions, anxiety, depression, trauma, and family dynamics. I utilize facets of multiple different disciplines and theories to tailor therapeutic efforts to each individual\'s needs and interests. I believe in a collaborative process that highlights each client’s agency and autonomy to develop their own path forward with reflection, insight, and accountability from their providers. When I am not in the office, I spend the majority of my time outside, in the woods, or on the water. I enjoy paddling, bikepacking, swimming, and roaming just about anywhere. I volunteer with the Arrowhead Critical Incident Response team and enjoy working with first responders and small communities across northern Minnesota. Though I enjoy spending time outside in almost any weather, I also enjoy reading, cooking, baking, and working with wool while stuck indoors. I am grateful that my retired sled dog is up for tagging along in all these activities!',
    specialization: 'I enjoy working with individuals navigating life transitions, anxiety, depression, trauma, and family dynamics. Utilizing a blend of therapeutic approaches, I strive to provide tailored support that fosters insight, agency, and accountability in a collaborative and affirming environment.',
    specialtiesList: [
        'Individuals/Couples/Families',
        'Kids/Teens/Adults',
        'Anxiety',
        'Depression',
        'Trauma',
        'TF-CBT',
        'Hypnosis',
        'Mindfulness Based Stress Reduction',
        'Acceptance and Commitment Therapy (ACT)',
        'LGBTQIA+',
    ],
    educationList: [
        'University of Minnesota - Bachelor’s Degree in Anthropology',
        'Vanderbilt University - Master’s of Education in Human Development Counseling',
    ]
};


export const providers = [
    heather_rose_carlson,
    christina_finlo,
    alex_ross,    
    peggy_romero,
    rebecca_oachs,
    adam_huot,
    claire_dzierzak,
    lidnzi_campbell,
];

export const providersMap = providers.reduce((map, provider) => {
    map[provider.id] = provider;
    return map;
}, {});

